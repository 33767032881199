<template>
  <v-card-text>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="editedItem.name"
          label="Название"/>
        <v-textarea
          v-model="editedItem.description"
          label="Описание"/>
        <v-integer
          v-model="editedItem.params.time"
          label="Плановый срок выполнения"
        />
        <v-switch
          v-model="editedItem.params.no_reports"
          label="Не включать в отчет"
        />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import VInteger from '../../../components/VInteger'
export default {
  name: 'FormTypeService',
  components: { VInteger },
  props: {
    editedItem: Object
  }
}
</script>

<style scoped>

</style>
