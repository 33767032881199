<script>
import { VTextField } from 'vuetify/es5/components/VTextField'

export default {
  name: 'v-integer',
  extends: VTextField,
  props: {
    value: {
      default: 0
    },
    negative: {
      default: false,
      type: Boolean
    },
    type: {
      default: 'number'
    },
    empty: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    fixValue () {
      let v = this.value
      if (v.length !== 0 || !this.empty) {
        v = parseInt(this.value)
        if ((v < 0 && !this.negative) || isNaN(v)) {
          v = 0
        }
      }
      this.$emit('input', v)
    }
  },
  mounted () {
    this.$on('blur', this.fixValue)
  }
}
</script>
